.wrapper {
  max-width: 100vw;
  height: calc(100% - 0.5px);
  position: relative;
  top: 0.5px;
  overflow-x: hidden;
}

.content {
  height: 400vh;
}

html {
  overflow: hidden;
  height: 100%;
}
body {
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; /* iOS velocity scrolling */
}

body {
  font-family: Arvo;
  font-size: 16px;

  color: #313131;
}

.title {
  position: fixed;

  font-family: Anton;
  font-weight: bold;
  font-style: italic;
  font-size: min(20vw, 30vh);

  z-index: 3;

  right: 0.5ch;
  color: black;

  pointer-events: none;
}

.title span {
  display: block;
  text-align: left;
}

.shadow {
  z-index: 10;
  mix-blend-mode: overlay;
  color: white;
}

.entity {
  position: fixed;
  pointer-events: none;
}

.type-planet {
  border-radius: 100%;
}

.type-box {
}

.type-text {
  font-size: 18px;
  color: white;
  padding: 16px;
  mix-blend-mode: difference;
}

.type-text h2 {
  font-size: min(10vw, 10vh);
  font-family: Arvo;
  font-weight: bold;
  font-style: italic;

  padding-bottom: 32px;
}

.type-text p + p {
  padding-top: 16px;
}
